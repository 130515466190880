import "../_imports/common";
import "../_imports/metronic";
import "@edith/metronic/sass/theme/pages/login/login-3.scss";

// TS interni
import Storage from "../components/storage/storage";

$(() =>
{
    // click bottone per login -> cancellazione localStorage
    $("button#login-button").on("click", () => Storage.remove());
});