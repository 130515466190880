declare const __LANG: { [key: string]: string };

export default function i18n(key: string, replace?: { [key: string]: string | number }): string {
    if (__DEBUG) {
        if (typeof __LANG[key] === "undefined") {
            console.warn(`Unknown language key: "${key}"`);
        }
    }
    let line = __LANG[key] || key;
    if (replace) {
        const keys = Object.keys(replace);
        // Ordino i placeholder in base alla lunghezza (desc) in modo da
        // evitare sostituzioni di parti di testo
        keys.sort((a, b) => b.length - a.length);
        for (const k of keys) {
            const v = replace[k];
            // Sostituzione placeholder
            line = line.replace(":" + k, typeof v === "string" ? v : v.toString());
        }
    }
    return line;
}
